import Vue from 'vue';
import Router, { NavigationGuard, NavigationGuardNext } from 'vue-router';
import VueCookies from 'vue-cookies';

Vue.use(Router);
Vue.use(VueCookies);

export enum Page {
  Home = 'home',
  Search = 'search',
  Test = '/this-is-a-test',
  Login = 'login',
  SiteDetails = 'site-details',
  SiteBooking = 'site-booking',
  BookingConfirmation = 'booking-confirmation',
  Favourites = 'favourites',
  Profile = 'profile',
  NotFound = 'not-found',
  Favorites = 'favorites',
}

export enum Path {
  Home = '/',
  Test = '/this-is-a-test',
  Search = '/s/sites',
  SiteDetails = '/explore/:state/:siteName',
  SiteBooking = '/explore/:state/:siteName/book',
  BookingConfirmation = '/explore/:state/:siteName/book/:sessionId/complete',
  Profile = '/dashboard',
  NotFound = '*',
  Favorites = '/favorites',
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: Path.Test,
      name: Page.Test,
      meta: { layout: 'default' },
      component: () =>
        import(/* webpackChunkName: "search" */ '@/pages/hello-world'),
      beforeEnter: (to, from, next): void => {
        next();
      },
    },
    {
      path: Path.Search,
      name: Page.Search,
      meta: { layout: 'search-layout' },
      component: () =>
        import(/* webpackChunkName: "search" */ '@/pages/search'),
      beforeEnter: (to, from, next): void => {
        next();
      },
    },
    {
      path: Path.SiteDetails,
      name: Page.SiteDetails,
      meta: { layout: 'default' },
      component: () =>
        import(/* webpackChunkName: "site-details" */ '@/pages/site-details'),
      beforeEnter: (to, from, next): void => {
        next();
      },
    },
    {
      path: Path.SiteBooking,
      name: Page.SiteBooking,
      meta: { layout: 'default' },
      component: () =>
        import(/* webpackChunkName: "site-booking" */ '@/pages/site-booking'),
      props: (route) => ({
        siteName: route.params.siteName,
        startDate: route.query.start,
        endDate: route.query.end,
        partner: route.query.utm_source,
        camperCount: parseInt(
          (route.query.number_of_people as string) ?? '0',
          10
        ),
      }),
    },
    {
      path: Path.BookingConfirmation,
      name: Page.BookingConfirmation,
      meta: { layout: 'default' },
      component: () =>
        import(
          /* webpackChunkName: "booking-confirmation" */ '@/pages/booking-confirmation'
        ),
      props: (route) => ({
        sessionId: route.params.sessionId,
        siteName: route.params.siteName,
      }),
      beforeEnter: (to, from, next): void => {
        next();
      },
    },
    {
      path: Path.Home,
      name: Page.Home,
      meta: {
        layout: 'comingsoon-layout',
      },
      component: () =>
        import(
          /* webpackChunkName: "coming-soon" */
          '@/pages/coming-soon'
        ),
    },
    {
      path: Path.NotFound,
      name: Page.NotFound,
      meta: {
        layout: 'default',
      },
      component: () =>
        import(
          /* webpackChunkName: "not-found" */
          '@/pages/not-found'
        ),
    },
    {
      path: Path.Favorites,
      name: Page.Favourites,
      meta: { layout: 'default' },
      component: () =>
        import(/* webpackChunkName: "site-details" */ '@/pages/favorites'),
      beforeEnter: (to, from, next): void => {
        next();
      },
    }
  ],
});
