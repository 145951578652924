import env from '@/config/env';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseService } from './_base';

// TODO: move to an environment variable
const { BASE_URL, ENV_NAME } = env.api;

class ConfigService extends BaseService {
  private apiKey = '';
  private readonly cache = {
    tentrrApiKey: '',
  };

  constructor() {
    super({ baseURL: `${BASE_URL}/admin-dash/${ENV_NAME}/config_key` });
  }

  public async getTentrrApiKey(): Promise<string> {
    if (!this.cache.tentrrApiKey) {
      // TODO: parameterize should there be additional types of keys
      await this.api.get('?keys=TENTRR_API_KEY');

      // Storing information in cache
      this.cache.tentrrApiKey = this.apiKey;

      return this.apiKey;
    } else {

      return this.cache.tentrrApiKey;
    }
  }

  // --------------------------------------------------------------------------
  // Event Handlers
  // --------------------------------------------------------------------------
  protected onRequest(config: AxiosRequestConfig): void {
    // TODO:
  }

  protected onRequestError(error: unknown): void {
    // TODO:
  }

  protected onResponse(response: AxiosResponse): void {
    const data = response.data as Record<string, string>;

    if (data.hasOwnProperty('TENTRR_API_KEY')) {
      this.apiKey = data.TENTRR_API_KEY;
    }
  }

  protected onResponseError(error: unknown): void {
    // TODO:
  }
}

const service = new ConfigService();

export { service as default, service as ConfigService };
